import React from 'react';

import classNames from 'classnames';

import { LabelHelper } from '@components/Forms/FormElement';

interface Props {
  label: string;
  id: string;
  name?: string;
  checked: boolean;
  onClick: () => void;
}

const PrimaryRadio: React.FC<Props> = ({ label, id, name, ...props }) => {
  const flag = !!props.checked;
  return (
    <div className="mr-auto RadioOption">
      <LabelHelper
        id={id}
        label={label}
        labelAfter
        classes={{
          label: classNames('text-xs RadioLabel', {
            'font-bold': props.checked,
          }),
        }}
        isFontBold={flag}
      >
        <input
          id={id}
          onChange={() => {}}
          name={name}
          type="radio"
          {...props}
          className="z-0 RadioInput"
        />
      </LabelHelper>
    </div>
  );
};

export default PrimaryRadio;
