// Libs
import React from 'react';

// Components
import Select, { SelectProps } from '@components/Formik/Select';

// Utils
import { getPhoneTypeLabel } from '@use-cases/profile';

// Hooks
import { useTranslation } from '@external/react-i18next';

// Types
import { PhoneType } from '@typings/graphql';

type Props = Omit<SelectProps, 'options'>;

const PhoneTypeSelect: React.FC<Props> = props => {
  const { t } = useTranslation();
  const options = [
    {
      label: getPhoneTypeLabel(t, PhoneType.Business),
      value: PhoneType.Business,
    },
    { label: getPhoneTypeLabel(t, PhoneType.Home), value: PhoneType.Home },
    { label: getPhoneTypeLabel(t, PhoneType.Mobile), value: PhoneType.Mobile },
  ];

  return <Select options={options} {...props} />;
};

export default PhoneTypeSelect;
