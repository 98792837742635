import { DIS } from './dis';

export namespace DOMINO {
  export interface Country {
    id: string;
    countryName: string;
    shortCode: string;
  }

  export interface Countries {
    countries: Country[];
  }

  export interface Language {
    id: string;
    shortCode: string;
    languageName: string;
    ispreferred: boolean;
  }

  export interface Languages {
    languages: Language[];
  }

  export interface AreaOfExpertise {
    id: string;
    value: string;
    detail: object;
  }
  export enum SharingPermissions {
    noSharing = 'No Sharing',
    shareWithClubAndDistrict = 'Share with my club',
    shareWithDistrictOfficer = 'Share with my district',
    shareWithAll = 'Share with all members',
  }

  export enum ClubStatus {
    Active = 'Active',
    Terminated = 'Terminated',
    Suspended = 'Suspended',
  }

  export interface PhoneOutput {
    country: string;
    countryId: string;
    extension: string | null;
    number: string;
    isPrimary: boolean;
    isFax: boolean;
    id?: string | null;
    action?: string;
  }

  export interface EmailOutput {
    email: string;
    isPrimary: boolean;
    id?: string;
    action?: string;
  }

  export interface WebsiteOutput {
    primaryWebsite: string;
    otherWebsites?: string;
    id?: string;
    action?: string;
  }

  export interface AddressBaseOutput {
    state: string | null;
    country: string | null;
    lineOne: string | null;
    city: string | null;
    postalCode: string | null;
    countryId: string;
    internationalProvince: string | null;
    lineThree: string | null;
    lineTwo: string | null;
    stateId: string | null;
    id?: string;
    action?: string;
    type: string;
  }

  export type AddressType =
    | DominoAddressType
    | 'BusinessAddresses'
    | 'Home'
    | 'LocalizedAddress'
    | 'SubscriptionMailingAddresses'
    | 'Project'
    | 'HostCountry'
    | 'Unknown'
    | 'MailingAddress'
    | 'PhysicalLocation';

  export type DominoAddressType =
    | 'Business'
    | 'Localized'
    | 'Subscription Mailing'
    | 'Rotary Business'
    | 'Physical Location';

  export interface AddressOutput extends AddressBaseOutput {
    type: AddressType;
  }

  export interface LanguageOutput {
    language: string;
    languageId: string;
  }

  export type PhoneType = 'Business' | 'Home';

  export type Weekday =
    | 'Monday'
    | 'Tuesday'
    | 'Wednesday'
    | 'Thursday'
    | 'Friday'
    | 'Saturday'
    | 'Sunday';

  export interface District {
    id: string;
    riDistrictId: number;
    districtDisId: string;
  }

  export type ClubType =
    | 'Rotary_Club'
    | 'Rotaract_Club'
    | 'Satellite_Club'
    | 'Rotaract_Satellite_Club';

  export type OrganizationBase = 'School' | 'Community' | 'University';

  export interface ClubRelationship {
    id: string;
    type: ClubType;
    startDate: string;
    endDate: string;
    childClubId?: string;
    childDisId?: string;
    parentClubId?: string;
    parentDisId?: string;
    clubName: string;
  }

  export interface Meeting {
    address: AddressBaseOutput | null;
    latitude: number | null;
    longitude: number | null;
    weekday: Weekday;
    time: string;
    comment: string | null;
    type: MeetingType;
    onlineLocation: string | null;
    languageId: string | null;
    language: string | null;
    frequency: string | null;
    name: string | null;
    locationNameOrDetail: string | null;
    locationVaries: boolean;
    detailForJoiningRemotely: string | null;
    meetingId: string | null;
  }

  export type EditOrgContactInfo = {
    contactInfo: ContactInfoType;
    id: string;
  };

  export interface EditOrgContactInfoPayload {
    contactInfo: ContactInfoType;
  }

  export type ContactInfoType = Partial<{
    addresses: AddressBaseInput[] | AddressBaseInput;
    emails: EmailInput[] | EmailInput;
    phones: PhoneInput[];
    websites: WebsitesInput[] | WebsitesInput;
    officialLanguage?: Omit<LanguageOutput, 'language'>;
  }>;

  export type AddressBaseInput = AddressBaseOutput & { action?: string };

  export type EmailInput = EmailOutput & { action?: string };

  export type WebsitesInput = Omit<WebsiteOutput, 'otherWebsites'> & {
    action?: string;
  };

  export type PhoneInput = Omit<PhoneOutput & { action?: string }, 'country'>;

  export interface Club {
    status: ClubStatus;
    phones: PhoneOutput[];
    addresses: AddressOutput[];
    riClubId: string;
    id: string;
    clubDisId: string;
    name: string;
    charteredDate: string;
    officialLanguage: LanguageOutput;
    emails: EmailOutput[];
    websites: WebsiteOutput;
    district: District | null;
    clubSponsorOf: ClubRelationship[];
    clubSponsoredBy: ClubRelationship[];
    organizationBase: OrganizationBase | null;
    meetings: Meeting[];
    type: ClubType;
  }

  export type MeetingType = 'Physical' | 'Online' | 'Hybrid';
  export interface Conference {
    id: string;
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    relatedRotaryYear: number;
    venueName: string;
    language: string;
    prAccommodation: string;
    comments: string;
    venueType: MeetingType;
    onlineLocation: string;
    locationNameOrDetail: string;
    detailForJoiningRemotely: string;
    districtId: string;
    conferenceAddress: AddressBaseInput;
    prAddress: AddressBaseInput;
    timeZone: string;
    isPresRepRequested: boolean;
    presRepAccPhone: Omit<PhoneInput, 'isPrimary' | 'isFax'>;
    presidentialRepresentative: PartialIndividualData;
    participatingDistricts: District[];
    feedbackList: Feedback[];
  }

  export enum FeedbackType {
    DG = 'DG',
    PR = 'PR',
  }
  export interface Feedback {
    id: string;
    feedbackType: FeedbackType;
  }

  export interface PartialIndividualData {
    id: string;
    firstName: string;
    lastName: string;
    middleName?: string | null;
    prefix?: string | null;
    suffix?: string | null;
    localizedName?: string | null;
  }

  export interface GetAllLeadershipsOutput {
    page: number;
    pageSize: number;
    totalCount: number;
    leadership: Leadership[];
  }
  export interface Leadership {
    id: string;
    endDate: string;
    startDate: string;
    isDelegated: boolean;
    roleId: string;
    role: string;
    individual: PartialIndividualData;
  }

  export interface State {
    code: string;
    stateName: string;
    countryCode: string;
  }

  export interface LeadershipRole {
    StartDate: string;
    EndDate: string;
  }

  export interface TerminationReasons {
    terminationReasonPk: string;
    reason: DIS.Reason;
    organizationType: string;
    donotAllowUserentry: null | boolean;
    status: string;
    nfKey: string;
  }

  export interface AreaOfFocus {
    id: string;
    value: string;
    Acronym: string;
    IsActive: null | boolean;
  }

  export interface TimeZone {
    id: string;
    value: string;
    timeZoneName: string;
    location: string;
    offset: string;
  }
  export interface IndividualLanguages {
    id: string;
    languageId: string;
    languageName: string;
    isPrimary: boolean;
    ispreferred: boolean;
  }

  export interface IndividualEmails {
    id: string;
    type: string;
    address: string;
    isPrimary: boolean;
  }

  export interface IndividualAddress {
    id: string;
    type: string;
    lineOne: string;
    lineTwo: string;
    lineThree: string;
    countryId: string;
    countryName: string;
    city: string;
    stateId: string;
    stateName: string;
    internationalProvince: string;
    postalCode: string;
    isPrimary: boolean;
  }

  export interface IndividualPhone extends Omit<PhoneOutput, 'country'> {
    id: string;
    type: string;
    number: string;
    countryId: string;
    countryName: string;
    extension: string;
    isPrimary: boolean;
    isFax: boolean;
  }

  export interface IndividualProfession {
    id: string;
    position: string;
    employer: string;
    occupation: string;
    isPrimary?: boolean;
    action?: string;
  }

  export interface IndividualExpertise {
    id: string;
    areaId: string;
    area: string;
    levelId: string;
    level: string;
    isPrimary?: boolean;
    action?: string;
  }

  export interface SharingPermissionBase {
    level: string;
    levelId: string;
  }

  export interface IndividualSharingPermissions {
    name: SharingPermissionBase | null;
    gender: SharingPermissionBase | null;
    email: SharingPermissionBase | null;
    phone: SharingPermissionBase | null;
    language: SharingPermissionBase | null;
    expertise: SharingPermissionBase | null;
    profession: SharingPermissionBase | null;
    address: SharingPermissionBase | null;
    fax: SharingPermissionBase | null;
    aboutMe: SharingPermissionBase | null;
    program: SharingPermissionBase | null;
    photo: SharingPermissionBase | null;
    dateOfBirth: SharingPermissionBase | null;
    sponsors: SharingPermissionBase | null;
    recognition: SharingPermissionBase | null;
    riIndividualId: SharingPermissionBase | null;
  }

  export type Gender = 'Male' | 'Female' | 'Not Given' | 'DNI' | 'Self';

  export type IndividualOutput = {
    id: string;
    firstName: string;
    lastName: string;
    middleName?: string | null;
    prefix?: string | null;
    suffix?: string | null;
    localizedName?: string | null;
    gender: string | null;
    photoUri: string | null;
    riIndividualId: number | null;
    classification: string | null;
    aboutMe: string | null;
    dateOfBirth: string | null;
    youthFlag: boolean;
    yearOfBirth: string | null;
    badgeName: string | null;
    recognitionOptOutFlag: boolean;
    languages: IndividualLanguages[];
    emails: IndividualEmails[];
    addresses: IndividualAddress[];
    phones: IndividualPhone[];
    professions: IndividualProfession[];
    expertises: IndividualExpertise[];
    sharingPermission: IndividualSharingPermissions;
  };

  export interface Fields {
    id: string;
    value: string;
    Description: string;
  }

  export interface ProgramField {
    id: string;
    value: string;
    Fields: Fields[];
  }

  export interface Programs {
    programs: ProgramField[];
  }

  export interface IndividualProgramsOutput {
    programs: DIS.IndividualProgram[];
  }

  export type IndividualBackgroundInput = {
    id: string;
    aboutMe: string;
    sharingPermissionId: string;
  };

  export type AccessLevel =
    | 'No Permission'
    | 'Limited Viewer'
    | 'Viewer'
    | 'Contributor'
    | 'Manager';

  export interface GetAccessLevelsInputRow {
    target: string;
    arguments?: { [key: string]: string };
  }

  export interface GetAccessLevelsInput {
    data: GetAccessLevelsInputRow[];
  }

  export interface GetAccessLevelsOutput {
    data: (GetAccessLevelsInputRow & { level: AccessLevel })[];
  }

  export interface OrganizationData {
    dominoId: string;
  }

  export interface DistrictInfo {
    id: string;
    riDistrictId: number;
    zone: number | null;
    status: string;
    phones: PhoneOutput[];
    addresses: AddressOutput[];
    emails: EmailOutput[];
    websites: WebsiteOutput;
    districtLegalName: string | null;
  }

  export interface PeopleDetail {
    membershipType: string;
    count: number;
  }
  export interface DistrictPeopleGraphDetails {
    peopleGraphDetails: PeopleDetail[];
    totalCount: number;
  }

  export interface ClubsGraphDetails {
    clubType: string;
    count: number;
  }

  export interface DistrictClubsGraphDetails {
    clubsGraphDetails: ClubsGraphDetails[];
    totalCount: number;
  }

  export interface DistrictOfficerDetail {
    id: string;
    firstName: string;
    lastName: string;
    middleName?: string | null;
    localizedName?: string | null;
    prefix?: string | null;
    suffix?: string | null;
    photoUri?: string | null;
    riIndividualId?: number | null;
    primaryEmail: {
      address: string | null;
    };
    primaryPhone: {
      country: string;
      countryId: string;
      extension: string | null;
      number: string;
      areaCode: string | null;
    };
    districtLeadership: DIS.DistrictLeadershipFromSearch[];
    membership: DIS.MembershipFromSearch[];
  }

  export interface ContactInfo {
    email: IndividualEmails[];
    phone: IndividualPhone[];
    address: IndividualAddress[];
  }

  export type ContactInfoDetails = {
    contactInfo: ContactInfoType;
    id: string;
  };

  export interface DistrictConferencesOutput {
    page: number;
    pageSize: number;
    totalCount: number;
    conferences: DistrictConference[];
  }

  export interface DistrictConference {
    id: string;
    districtId: string;
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    timeZone: string;
    relatedRotaryYear: number;
    venueName: string;
    city: string;
    stateProvince: string;
    country: string;
    language: string;
    prAccommodation: string;
    prAccAddr1: string;
    prAccAddr2: string;
    prAccAddr3: string;
    prAccPhone: string;
    comments: string;
    participatingDistricts: ParticipatingDistrict[];
    venueType: MeetingType;
    onlineLocation: string;
    locationNameOrDetail: string;
    detailForJoiningRemotely: string;
    conferenceAddress: AddressBaseOutput;
    prAddress: AddressBaseOutput;
    presidentialRepresentative: PresidentialRepresentative;
    isPresRepRequested: boolean;
    presRepAccPhone: PhoneInput;
    feedbackList: ConferenceFeedbackList[];
  }

  export interface ParticipatingDistrict {
    id: string;
    riDistrictId: number;
  }

  export interface PresidentialRepresentative {
    id: string;
    firstName: string;
    middleName: string;
    lastName: string;
  }

  export type ConferenceFeedbackList = {
    id: string;
    feedbackType: FeedbackType;
  };

  export interface DistrictConferencesRequestParams {
    associatedDistrictId: string;
    presidentialRepresentativeId?: string;
    MaxStartDate?: string;
    MinStartDate?: string;
  }

  export interface DistrictByQueryParams {
    status: string;
    riDistrictId?: number;
    page: number;
    pageSize: number;
  }

  export interface DistrictsByQuery {
    page: number;
    pageSize: number;
    totalCount: number;
    districts: DistrictInfo[];
  }
}
