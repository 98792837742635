import { isDominoFeature } from '@utils/isDominoFeature';

import { backendT } from '@i18n';

import { DIS } from '@typings/dis';
import { DOMINO } from '@typings/domino';
import { QueryResolvers } from '@typings/resolvers';

export const knownPermissions: Record<string, string> = {
  // no-sharing key has translation Share with only my club officers and district officers,
  // share-with-my-club => Share with my club members and district officer,
  // share-with-my-district => Share with my district members

  // TODO: create a strategy where we rely on the keys or IDs and determine the
  // appropriate text to display to the user on our end and manage it all in one place
  [DIS.SharingPermissions.noSharing]: backendT(
    'sharing-permission.no-sharing',
    'Share with only my club officers and district officers'
  ),
  [DIS.SharingPermissions.shareWithClubAndDistrict]: backendT(
    'sharing-permission.club',
    'Share with my club members and district officer'
  ),
  [DIS.SharingPermissions.shareWithDisctrictOfficer]: backendT(
    'sharing-permission.district',
    'Share with my district members'
  ),
  [DIS.SharingPermissions.shareWithAll]: backendT(
    'sharing-permission.all-members',
    'Share with all members'
  ),
};

function getTranslation(permission: string): string {
  switch (permission) {
    case DOMINO.SharingPermissions.noSharing:
      return backendT(
        'sharing-permission.no-sharing',
        'Share with only my club officers and district officers'
      );
    case DOMINO.SharingPermissions.shareWithClubAndDistrict:
      return backendT(
        'sharing-permission.club',
        'Share with my club members and district officer'
      );
    case DOMINO.SharingPermissions.shareWithDistrictOfficer:
      return backendT(
        'sharing-permission.district',
        'Share with my district members'
      );
    case DOMINO.SharingPermissions.shareWithAll:
      return backendT(
        'sharing-permission.all-members',
        'Share with all members'
      );
    default:
      return permission;
  }
}

export const getAllSharingPermissionsResolver: QueryResolvers['getAllSharingPermissions'] = async (
  _source,
  args: {},
  { dataSources }
) => {
  const results = isDominoFeature('FEATURE_DOMINO_METADATA')
    ? await dataSources.dominoMetadata.getSharingPermissions()
    : await dataSources.dis3.getSharingPermissions();

  return results.map(permission => ({
    id: permission.id,
    label: getTranslation(permission.value),
  }));
};
