// Libs
import React from 'react';

// Components
import Icon from '@components/Icon';

// Hooks
import { useTranslation } from '@external/react-i18next';

interface Props {
  onClick: () => any;
}

const DeleteButton: React.FC<Props> = props => {
  const { t } = useTranslation();

  return (
    <button
      {...props}
      className="mb-0 flex items-center h5 alternate text-gray-400 active:text-gray-600"
      type="button"
    >
      <Icon name="ui/trashcan" className="mr-3" size="14" />
      {t('edit-profile.delete', 'Delete')}
    </button>
  );
};

export default DeleteButton;
