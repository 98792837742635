// Libs
import React from 'react';

// Components
import Icon from '@components/Icon';

interface Props {
  label: string;
  onClick: () => void;
  color?: string;
}

const AddAnotherButton: React.FC<Props> = ({ label, onClick, color }) => {
  return (
    <button
      type="button"
      className="mb-0 h5 alternate text-bright-blue-600 active:text-dark-blue-400"
      onClick={onClick}
    >
      <div className="flex items-center">
        <Icon name="ui/plus" color={color} className="mr-3" size="14" />
        {label}
      </div>
    </button>
  );
};

export default AddAnotherButton;
