import React from 'react';

import AddressFields from '@components/Formik/Address';

import { getAddressFieldName } from '../utils';
import AddressTypeSelect from './AddressTypeSelect';

import { useTranslation } from '@external/react-i18next';

interface AddressFieldProps {
  index: number;
  countryId: string;
}

const AddressField: React.FC<AddressFieldProps> = ({ index, countryId }) => {
  const { t } = useTranslation();

  return (
    <div>
      <AddressTypeSelect
        name={getAddressFieldName('type', index)}
        label={t('edit-contact-information.address.form.type-label', 'Type')}
        required
      />
      <AddressFields
        countryId={countryId}
        name={{
          lineOne: getAddressFieldName('lineOne', index),
          lineTwo: getAddressFieldName('lineTwo', index),
          lineThree: getAddressFieldName('lineThree', index),
          countryId: getAddressFieldName('countryId', index),
          city: getAddressFieldName('city', index),
          stateId: getAddressFieldName('stateId', index),
          state: getAddressFieldName('state', index),
          postalCode: getAddressFieldName('postalCode', index),
          internationalProvince: getAddressFieldName(
            'internationalProvince',
            index
          ),
          hasStates: getAddressFieldName('hasStates', index),
        }}
      />
    </div>
  );
};

export default AddressField;
