// Types
import { isEqual } from 'lodash';

import { FormAddressItem, FormEmailItem, FormPhoneItem } from '@domain/profile';

import {
  Address,
  AddressType,
  Email,
  EmailType,
  PhoneNumber,
  PhoneType,
} from '@typings/graphql';

export const getAddressFieldName = (
  name: keyof Address | string,
  index: number
) => `addresses[${index}].${name}`;

export const getNewAddress = (): Address => ({
  type: AddressType.Home,
  countryId: '',
});

export const getNewEmail = (): Email => ({
  address: '',
  type: EmailType.Personal,
});

export const getEmailFieldName = (index: number, name: string) =>
  `emails[${index}].${name}`;

export const getPhoneFieldName = (index: number, name: string) =>
  name !== '' ? `phones[${index}].${name}` : `phones[${index}]`;

export const getNewPhoneNumber = (): PhoneNumber => ({
  countryId: '',
  type: PhoneType.Mobile,
  country: '',
  number: '',
});

export const processContactInfoForDomino = (
  formInfo: FormEmailItem[] | FormAddressItem[] | FormPhoneItem[],
  submittedInfo: FormEmailItem[] | FormAddressItem[] | FormPhoneItem[]
) => {
  const formInfoIdValuePair = new Map(formInfo.map(info => [info.id, info]));
  const formInfoIds = new Set(formInfoIdValuePair.keys());

  const submittedInfoIdValuePair = new Map(
    submittedInfo.map(info => [info.id, info])
  );
  const submittedInfoIds = new Set(submittedInfoIdValuePair.keys());

  // If Submitted Info has missing id's, they are considered deleted and action is "Deleted".
  const deletedInfoIds = Array.from(formInfoIds).filter(info => {
    return !submittedInfoIds.has(info);
  });

  const deletedInfos = deletedInfoIds.map(infoId => {
    const deletedInfo = formInfoIdValuePair.get(infoId);
    delete deletedInfo?.__typename;
    return { ...(deletedInfo || { infoId }), action: 'Delete' };
  });

  const addedUpdatedInfos = submittedInfo.map(
    (info: FormEmailItem | FormAddressItem | FormPhoneItem) => {
      const actionUpdatedInfo = { ...info };
      // If Submitted Info does not have id, its a newly added Info and action is "Added".
      if (!info.id) {
        actionUpdatedInfo.action = 'Add';
      } else {
        const selectedInfo = formInfoIdValuePair.get(info.id);

        if (selectedInfo) {
          // If Form Info values are different from submitted Info values, action is "Updated"
          if (!isEqual(info, selectedInfo)) {
            actionUpdatedInfo.action = 'Update';
          }
        }
      }

      delete actionUpdatedInfo.__typename;
      return actionUpdatedInfo;
    }
  );

  return [...addedUpdatedInfos, ...deletedInfos];
};
