import { useQuery, gql } from '@apollo/client';
import {
  SharingPermissionsQuery,
  SharingPermissionsQueryVariables,
} from '../types/operations';
import _ from 'lodash';

export const useSharingPermissions = () => {
  return _.pick(
    useQuery<SharingPermissionsQuery, SharingPermissionsQueryVariables>(
      gql`
        query SharingPermissions {
          sharingPermissions: getAllSharingPermissions {
            id
            label
          }
        }
      `
    ),
    'error',
    'loading',
    'data'
  );
};
